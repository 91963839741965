import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "src/helpers"
import classNames from "classnames"

import {
  Accordion,
  AccordionWrapper,
  BlogCards,
  Button,
  Cards,
  Container,
  CTA,
  Features,
  Heading,
  Hero,
  Highlight,
  Image,
  Layout,
  SEO,
} from "src/sws-ui"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import "src/css/pages/_testimonial.scss"
import Homepage from "../graphql/moneytrasnfer"
import RemoveLog from "../components/removeLog"
import ReactMarkdown from "react-markdown"

let accordionItems = []

//todo remark
//template หน้าแรกของ english
const params = {
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  loop: true,
  slidesPerView: 1,
  autoplay: {
    delay: 5000,
  },
}

const featureItems = [
  {
    heading: "Great Rates",
    icon: "great-rates.png",
    content:
      "Have we mentioned that we offer the best Australian Dollar to Thai Baht  rates? Yeah, we are pretty proud of it. Our unique partnerships allow us to offer you the best exchange rates in real-time through FX sales. When you transfer money internationally with Smartway System, your funds go further.",
  },
  {
    heading: "Secure",
    icon: "secure.png",
    content: `In this day and age, safety with online money transactions is more important than ever. At Smartway System, we work with large, established banks in Australia that require extra identification protocols. We will never allow third-parties to see our client's details.`,
  },
  {
    heading: "No Hidden Fees",
    icon: "no-hidden-fee.png",
    content: `What you see is what you get with us! We believe in complete transparency with our customers. That's why we are always upfront with your about how we do business.`,
  },
]

let parallax = null
const blogItems = [
  {
    image:
      "./images/blog/a-young-girl-listens-to-a-music-or-podcast-while-traveling-in-a-train.png",
    heading: "The Safest Way for Sending Money Home...",
    content: "Transaction will take an hour to complete their transaction...",
    linkUrl: "/blog",
  },
  {
    image:
      "./images/blog/a-young-girl-listens-to-a-music-or-podcast-while-traveling-in-a-train.png",
    heading: "The Safest Way for Sending Money Home...",
    content: "Transaction will take an hour to complete their transaction...",
    linkUrl: "/blog",
  },
  {
    image:
      "./images/blog/a-young-girl-listens-to-a-music-or-podcast-while-traveling-in-a-train.png",
    heading: "The Safest Way for Sending Money Home...",
    content: "Transaction will take an hour to complete their transaction...",
    linkUrl: "/blog",
  },
]

//moneytransfer

const IndexPage = () => {
  const allState = useSelector(state => state)
  const Home = Homepage()
  // Advertise

  const viewportWidth = useWindowWidth()
  const [parallaxVal, setParallaxVal] = useState(8.1)
  const [loggedIn, setLoggedIn] = useState(
    typeof window !== "undefined" && !!localStorage.getItem("smartway_auth")
  )

  const homepageClasses = classNames("homepage", {
    "logged-in": loggedIn,
  })

  const dispatch = useDispatch()
  // const [ad, showAd] = useState(true)

  useEffect(() => {
    if (viewportWidth <= 992) {
      setParallaxVal(14)
    }
  }, [])

  // const loadAdData = async () => {
  //     dispatch(getAdvertize())
  // }

  // loadAdData();

  // const adImg = allState.currency.getAdvertize;

  // const getAd = dispatch(getAdvertize())
  // const adImg = getAd

  var homepagedata
  accordionItems=[]
  //loop through api
  Home.allMarkdownRemark.nodes.map((data, counter) => {
    let curLang = "en"
    if (typeof window !== "undefined") {
      curLang = localStorage.getItem("lang") || "en"
    }
    curLang = curLang.toLowerCase() === "en" ? "English" : "Thi"
    switch (data.frontmatter.slug) {
      case "home":
        if (curLang === data.frontmatter.category_language) {
          homepagedata = data.frontmatter
          accordionItems.push({
            heading: homepagedata.FAQ_question_one,
            content: (
              <React.Fragment>
                <ReactMarkdown>{homepagedata.FAQ_answer_one}</ReactMarkdown>
              </React.Fragment>
            ),
          })
          accordionItems.push({
            heading: homepagedata.FAQ_question_two,
            content: (
              <React.Fragment>
                <ReactMarkdown>{homepagedata.FAQ_answer_two}</ReactMarkdown>
              </React.Fragment>
            ),
          })
          accordionItems.push({
            heading: homepagedata.FAQ_question_three,
            content: (
              <React.Fragment>
                <ReactMarkdown>{homepagedata.FAQ_answer_three}</ReactMarkdown>
              </React.Fragment>
            ),
          })
          accordionItems.push({
            heading: homepagedata.FAQ_question_four,
            content: (
              <React.Fragment>
                <ReactMarkdown>{homepagedata.FAQ_answer_four}</ReactMarkdown>
              </React.Fragment>
            ),
          })
          accordionItems.push({
            heading: homepagedata.FAQ_question_five,
            content: (
              <React.Fragment>
                <ReactMarkdown>{homepagedata.FAQ_answer_five}</ReactMarkdown>
              </React.Fragment>
            ),
          })

        }

      default:
        break
    }
  })
const fnSetAccordionItems = () => {
  // accordionItems = [
  //   {}
  // ]
}
  const cardItems = [
    <div className="card" key={"keyRegister"}>
      <span>1</span>
      <Heading size={3}>{homepagedata.easy_transfer_heading_one}</Heading>
      <ReactMarkdown>
        {homepagedata.easy_transfer_description_one}
      </ReactMarkdown>
    </div>,
    <div className="card" key={"verifyYourIdentify"}>
      <span>2</span>
      <Heading size={3}>{homepagedata.easy_transfer_heading_two}</Heading>
      <ReactMarkdown>
        {homepagedata.easy_transfer_description_two}
      </ReactMarkdown>
    </div>,
    <div className="card" key={"ProvideBankDetails"}>
      <span>3</span>
      <Heading size={3}>{homepagedata.easy_transfer_heading_three}</Heading>
      <ReactMarkdown>
        {homepagedata.easy_transfer_description_three}
      </ReactMarkdown>
    </div>,
    <div className="card" key={"ShooseAnAmount"}>
      <span>4</span>
      <Heading size={3}>{homepagedata.easy_transfer_heading_four}</Heading>
      <ReactMarkdown>
        {homepagedata.easy_transfer_description_four}
      </ReactMarkdown>
    </div>,
    <div className="card" key={"cardTransfer"}>
      <span>5</span>
      <Heading size={3}>{homepagedata.easy_transfer_heading_five}</Heading>
      <ReactMarkdown>
        {homepagedata.easy_transfer_description_five}
      </ReactMarkdown>
    </div>,
    <div className="card" key={"cardTracking"}>
      <span>6</span>
      <Heading size={3}>{homepagedata.easy_transfer_heading_six}</Heading>
      <ReactMarkdown>
        {homepagedata.easy_transfer_description_six}
      </ReactMarkdown>
    </div>,
  ]
  return (
    <div className={homepageClasses}>
      <Layout homepage={true}>
        <SEO title="Home" />
        <Hero
          heading={homepagedata.banner_heading}
          subHeading={homepagedata.banner_description}
          button={
            <Button color="blue" to="/en/login">
              {homepagedata.banner_button_text}
            </Button>
          }
          image={
            homepagedata.banner_image.split("/")[
              homepagedata.banner_image.split("/").length - 1
            ]
          }
          row="row-1"
        />
        <Container gridTemplateRows="14" className={"p-4"}>
          <div className="highlight__group smartway-container col-full row-3">
            <Highlight
              heading={homepagedata.fast_transaction_heading_one}
              align="left"
              row="row-2"
            >
              <p>{homepagedata.fast_transaction_description_one}</p>
            </Highlight>
            <Image
              src={
                homepagedata.fast_transaction_image_one.split("/")[
                  homepagedata.fast_transaction_image_one.split("/").length - 1
                ]
              }
              row="row-2 row-md-3 "
              col="col-7-12 col-md-center col-sm-center end-minus-5"
            />
          </div>

          <div className="highlight__group smartway-container col-full row-4">
            <Highlight
              heading={homepagedata.fast_transaction_heading_two}
              align="right"
              row="row-3"
            >
              <p>{homepagedata.fast_transaction_description_two} </p>
            </Highlight>
            <Image
              src={
                homepagedata.fast_transaction_image_two.split("/")[
                  homepagedata.fast_transaction_image_two.split("/").length - 1
                ]
              }
              row="row-3 row-md-4"
              col="col-1-6 col-md-center col-sm-center end-minus-5"
            />
          </div>

          <div className="highlight__group smartway-container col-full row-5">
            <Highlight
              heading={homepagedata.fast_transaction_heading_three}
              align="left"
              row="row-5 row-md-6"
            >
              <p>{homepagedata.fast_transaction_description_three}</p>
            </Highlight>
            <Image
              src={
                homepagedata.fast_transaction_image_three.split("/")[
                  homepagedata.fast_transaction_image_three.split("/").length -
                    1
                ]
              }
              row="row-4-5 row-md-7"
              col="col-7-12 col-md-center col-sm-center end-minus-5"
            />
          </div>

          <div className="highlight__group smartway-container col-full row-6">
            <Highlight
              heading={homepagedata.fast_transaction_heading_four}
              align="right"
              row="row-5 row-md-6"
            >
              <p>{homepagedata.fast_transaction_description_four}</p>
            </Highlight>
            <Image
              src={
                homepagedata.fast_transaction_image_four.split("/")[
                  homepagedata.fast_transaction_image_four.split("/").length - 1
                ]
              }
              row="row-4-5 row-md-7"
              col="col-1-6 col-md-center col-sm-center"
            />
          </div>
        </Container>

        <CTA
          row="row-7 row-md-9 pl-3 pr-3 "
          heading={homepagedata.get_the_app_heading}
          description={homepagedata.get_the_app_description}
        />
        <Container gridTemplateRows="14">
          <Features
            items={featureItems}
            row="row-8 row-md-10"
            //   app_feature_description_one={
            //     homepagedata.app_feature_description_one
            //   }
            //   app_feature_description_two={
            //     homepagedata.app_feature_description_two
            //   }
            //   app_feature_description_three={
            //     homepagedataapp_feature_description_three
            //   }
            //   app_feature_heading_one={homepagedata.app_feature_heading_one}
            //   app_feature_heading_three={homepagedata.app_feature_image_two}
            //   app_feature_heading_two={homepagedata.app_feature_heading_three}
            //   app_feature_image_one={homepagedata.app_feature_image_one}
            //   app_feature_image_three={homepagedata.app_feature_image_three}
            //   app_feature_image_two={homepagedata.app_feature_image_two}
            {...homepagedata}
          />
          <div className="row-9 row-md-11 smartway-container col-full col-sm-full testimonial--wrapper">
            <div
              className="col-1-9 col-md-1-11 row-1 row-md-1 col-sm-full testimonial--image-wrapper"
              style={{ zIndex: 3 }}
            >
              <Image
                src={
                  homepagedata.testominal_slider_image.split("/")[
                    homepagedata.testominal_slider_image.split("/").length - 1
                  ]
                }
              ></Image>
            </div>
            <div
              className="col-2-11 row-1 testimonial--tri"
              style={{ zIndex: 2 }}
            >
              <Image src="greytri.png"></Image>
            </div>
            <div
              className="col-7-12 row-1 testimonial--tri testimonial--tri-3"
              style={{ zIndex: 1 }}
            >
              <Image src="greytri.png"></Image>
            </div>
            <div
              className="col-8-12 col-md-1-11 row-1 row-md-1 row-sm-2 swiper--container"
              style={{ zIndex: 4 }}
            >
              <Swiper {...params}>
                <div>
                  <div className="testimonial--item">
                    <Heading size={2}>
                      {homepagedata.testominal_slider_heading_one}
                    </Heading>
                    <p>{homepagedata.testominal_slider_description_one}</p>
                  </div>
                </div>
                <div>
                  <div className="testimonial--item">
                    <Heading size={2}>
                      {homepagedata.testominal_slider_heading_two}
                    </Heading>
                    <p>{homepagedata.testominal_slider_description_two}</p>
                  </div>
                </div>
                <div>
                  <div className="testimonial--item">
                    <Heading size={2}>
                      {homepagedata.testominal_slider_heading_three}
                    </Heading>
                    <p>{homepagedata.testominal_slider_description_three}</p>
                  </div>
                </div>
              </Swiper>
            </div>
            <Cards
              heading={homepagedata.easy_transfer_section_main_title}
              content={homepagedata.easy_transfer_section_main_description}
              cards={cardItems}
              {...homepagedata}
              row="row-10 row-md-12"
              button={true}
            />
          </div>
          <AccordionWrapper
            heading={homepagedata.FAQ_heading}
            subheading={"all questions"}
            linkText={homepagedata.FAQ_button_link_text}
            linkUrl={homepagedata.FAQ_button_link_text_url}
            row="row-11 row-md-13 col-sm-full p-3"
            theme="green"
          >
            <Accordion accordionItems={accordionItems} theme="green" />
          </AccordionWrapper>
          <BlogCards
            heading={homepagedata.blog_main_title}
            content={homepagedata.blog_main_description}
            row="row-12 row-md-14"
            cards={blogItems}
            {...homepagedata}
            to={"/blog"}
          />
        </Container>
      </Layout>
    </div>
  )
}

export default IndexPage
